<template>
  <div class="home">
    <div class="wrapper">
      <div class="logo">
        <img src="../assets/main_logo.png" alt="TOM STYLE by YK JEONG" />
      </div>
      <div class="buttons">
        <a :href="getLink()" v-if="!isIOS15()">
          <div class="button">
            <span>TOM STYLE by YK</span>
          </div>
        </a>
        <router-link to="/notice" v-if="isIOS15()">
          <div class="button">
            <span>TOM STYLE by YK</span>
          </div>
        </router-link>
        <router-link to="/guide/vr">
          <div class="button">
            <span>VR Store</span>
          </div>
        </router-link>
        <router-link to="/guide/style">
          <div class="button">
            <span>TOM STYLE by ME</span>
          </div>
        </router-link>
      </div>
    </div>
    <div class="bottom">
      <img
        class="tom-greyhound"
        src="../assets/re_main_logo_tomgreyhound.png"
        alt="Tom Greyhound"
      />
    </div>
    <div class="powered-by">
      <img src="../assets/logo_poweredbyfassker.png" alt="Powered By FASSKER" />
    </div>
    <div class="share">
      <img src="../assets/main_share.png" alt="share" @click="onClickShare" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  methods: {
    isIOS15() {
      return (
        navigator.userAgent.indexOf("iPhone OS 15_0") !== -1 ||
        (navigator.platform === "MacIntel" &&
          typeof navigator.standalone !== "undefined" &&
          navigator.maxTouchPoints > 2 &&
          navigator.userAgent.indexOf("Version/15") !== -1)
      );
    },
    getLink() {
      const isAndroidWebView = /(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv)/i.test(
        navigator.userAgent
      );
      const url = new URL(window.location.href);
      const protocol = url.protocol.indexOf("https:") !== -1 ? "https" : "http";
      if (isAndroidWebView) {
        return `intent://${url.host}/pano#Intent;scheme=${protocol};package=com.android.chrome;end`;
      }
      if (url.host.indexOf("localhost") === 0 || url.host.indexOf("192.168.") === 0) {
        return "https://tomstyle21winter.fassker.net/pano";
      }
      return `${protocol}://${url.host}/pano`;
    },
    copyStringToClipboard(event, string) {
      function handler(e) {
        e.preventDefault();
        var el = document.createElement("input");
        document.body.appendChild(el);
        el.value = string;
        el.select();
        if (!document.execCommand("copy")) {
          // error
        }
        document.body.removeChild(el);
      }

      document.addEventListener("copy", handler, { once: true });
      document.dispatchEvent(new CustomEvent("copy", event));
    },
    onClickShare(event) {
      const shareData = {
        // title: "TOM STYLE by YK JEONG",
        text: "TOM STYLE by YK JEONG",
        url: window.location.href,
      };
      if ((navigator.canShare && navigator.canShare(shareData)) || navigator.share) {
        navigator
          .share(shareData)
          .then(function () {
            //
          })
          .catch(function () {
            //
          });
      } else if (navigator.clipboard) {
        navigator.clipboard.writeText(shareData.url);
        alert("주소를 복사했습니다. 공유하려는 곳에 붙여넣으세요.");
      } else {
        this.copyStringToClipboard(event, shareData.url);
        alert("주소를 복사했습니다. 공유하려는 곳에 붙여넣으세요.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  line-height: 1;
  .wrapper {
    width: 287px;
    height: 496px;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: calc((100% - 70px) / 2 - (496px / 2));
  }
  .logo {
    width: 204px;
    margin: 0 auto;
    margin-bottom: 24px;
    img {
      width: 100%;
    }
  }
  .buttons {
    width: 287px;
    margin: 0 auto;
    left: 0;
    right: 0;
    .button {
      position: relative;
      height: 33px;
      background-image: url("../assets/main_button.png");
      background-size: 287px 33px;
      font-family: "GothamSSm";
      font-size: 11px;
      font-weight: 500;
      line-height: 30px;
      text-align: left;
      margin-top: 10px;
      &::after {
        display: inline-block;
        position: absolute;
        content: " ";
        width: 6px;
        height: 8px;
        top: 12px;
        right: 20px;
        background-image: url("../assets/arrow_bk.png");
        background-size: 100%;
        background-position: left;
      }
      span {
        display: inline-block;
        margin: 0 20px;
        color: #000;
        vertical-align: middle;
        height: 100%;
      }
    }
  }
  .bottom {
    width: 100%;
    margin-top: 28px;
    position: fixed;
    bottom: 54px;
    .tom-greyhound {
      width: 87px;
    }
  }
  .powered-by {
    position: fixed;
    width: 100%;
    height: 33px;
    bottom: 0;
    border-top: 1px solid #c6c6c6;
    img {
      margin-top: 12px;
      width: 84px;
    }
  }
  .share {
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 2;
    img {
      width: 43px;
      height: 43px;
    }
  }
}
</style>
